 .site {
   margin-top: 30px;
 }

 .state {
   border: 1px solid #f0f0f0;
   border-radius: 2px;
   width: 90%;
   margin: auto;
 }

 .start {
   margin-left: 500px;
   margin-top: 100px;
 }

 .start_b {
   margin-left: 150px;
   margin-top: 100px;
 }

 .star_bt {
   margin-top: 100px;
 }

 .head1 {
   width: 70%;
   margin: auto;
   margin-top: 10px;
 }

 .body1 {
   margin: auto;
   margin-top: 10px;
 }

 .zong_01 {
   min-height: 100px;
   border-bottom: 1px solid rgb(214, 212, 212);
   padding: 0 5px;
   margin: 10px 0;
   position: relative;
 }

 .tab2 {
   width: 70%;
 }

 .posi_tab2 {
   position: absolute;
   right: -50px;
   top: 0;
 }

 .btn_01 {
   float: right;
 }

 .btn_02 {
   position: absolute;
   right: 10px;
   bottom: 10px;
 }

 .dealer-item+span {
   margin-left: 10px;
 }

 .address {
   width: 120px;
 }

 .address+span {
   padding: 0 5px 0 5px;
 }

 .release-evaluation .ant-form-item {
   width: 100%;
 }

 .ant-input-affix-wrapper>input.ant-input {
   padding-left: 5px;
 }

 .zong_evaluate {
   width: 90%;
   height: 300px;
   display: flex;
   justify-content: center;
   border: 1px solid rgb(209, 205, 205);
   margin: auto;
   margin-top: 30px;
   border-radius: 5px;
 }

 .zong_evaluate2 {
   width: 90%;
   margin: auto;
 }

 .tab1_evaluate {
   width: 30%;
   height: 200px;
   margin: auto;
 }

 .tab2_evaluate {
   width: 50%;
   height: 200px;
   margin: auto;
 }

 .table_evaluate {
   width: 90%;
   margin: auto;
   margin-top: 50px;
   border-radius: 5px;

 }

 .Evaluate .ant-form-item {
   margin-bottom: 5px;
 }

 .zong_information {
   width: 95%;
   display: flex;
   flex-direction: column;
   border: 1px solid rgb(8, 8, 8);
   margin-top: 20px;
   border-radius: 5px;
   padding-bottom: 10px;
 }

 .Purchaser {
   width: 99%;
   height: 200px;
   border: 1px solid rgb(8, 8, 8);
   margin: 10px auto 0;
   border-radius: 5px;
   padding: 0 20px;
 }

 .region {
   margin-top: 30px;
   padding: 0 100px;
 }

 .authentication {
   margin-top: 40px;
 }

 .tab1_lookingForProducts {
   height: 300px;
   margin: auto;
 }

 .lookingForProducts .ant-form-item {
   margin-bottom: 0;
 }


 .list_box {
   border-bottom: 1px solid #e8e8e8;
   padding: 10px 5px;
   height: 100px;
 }

 .list_box1 {
   border-bottom: 1px solid #e8e8e8;
   padding: 10px 5px;
   height: 100px;
 }

 .list-img-box {
   width: 90px;
   height: 90px;
   margin-right: 15px;
 }

 .merchant {
   max-width: 265px;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
 }

 .div_box {
   width: 60%;
 }

 .div_box div {
   width: 30%;
   display: inline-block;
 }

 .div-list-wid div {
   width: 32%;
   display: inline-block;
 }

 .tab2_lookingForProducts {
   height: 350px;
   margin-top: 10px;
 }

 .mySupplier_frame {
   width: 99%;
   height: 300px;
   border: 1px solid rgb(8, 8, 8);
 }

 .mySupplier_zong01 {
   display: flex;
   justify-content: center;
 }

 .mySupplier_picture {
   width: 20%;
   height: 150px;
 }

 .mySupplier_img {
   height: 150px;
 }

 .mySupplier_texts {
   width: 60%;
 }

 .mySupplier-left {
   margin-right: 20px;
   border: 1px solid #e8e8e8;
 }

 .mySupplier-right {
   width: 100%;
 }

 .mySupplier-right-con {
   height: 100%;
   max-height: 700px;
   overflow-y: scroll;
   padding-right: 30px;
   margin-right: -30px;
 }

 .mySupplier_words {
   width: 60%;
   height: 150px;
 }

 .mySupplier_file {
   width: 20%;
   height: 150px;
   text-align: right;
 }

 .mySupplier-box ::-webkit-scrollbar-thumb {
   background: #ccc;
   border-radius: 3px;
 }

 .mySupplier-box ::-webkit-scrollbar {
   width: 2px;
 }

 .mySupplier-box ::-webkit-scrollbar-track {
   background: #f2f2f2;
   width: 0;
 }

 .da {
   display: flex;
   justify-content: center;
 }

 .mySupplier_zong02 {
   width: 20%;
   height: 400px;
 }

 .a {
   width: 100px;
   height: 50px;
   border: 1px solid #ccc;
 }

 .a h3 {
   text-align: center;
   margin-top: 10px;
 }

 .mySupplier_zong03 {
   width: 80%;
 }

 .cp-list {
   display: flex;
   flex-wrap: wrap;
   margin: auto;
 }

 .cp-list .cp-item {
   margin-top: 30px;
   width: 33%;
 }

 .cp-list .cp-item span {
   width: 48%;
   display: inline-block;
 }

 .cp-item {
   margin-top: 20px;
 }

 .cp-item p {
   margin-bottom: 6px;
 }

 .cp-item .ant-image-img {
   border-radius: 12px;
   border: 1px solid #f5f5f5;
 }

 .accountA {
   border: 1px solid rgb(158, 158, 158);
   padding: 20px 20px;
 }

 .accounta {
   border: 1px solid rgb(158, 158, 158);
   padding: 10px 10px;
 }

 .detailed_btn1 {
   margin-top: 30px;
 }

 .releaseInformation_btn {
   margin: 20px 0px;
 }

 .quotation_new {
   border: 1px solid rgb(158, 158, 158);
   padding: 10px 20px;
   display: flex;
   align-items: center;
   border-radius: 10px;
   margin-top: 8px;
 }

 .quotation_new_info {
   width: 80%;
   margin-left: 16px;
 }

 .reconciliationDetails_zong {
   display: flex;
 }

 .supplier {
   width: 50%;
   border: 1px solid rgb(158, 158, 158);
   padding: 10px 10px;
 }

 .my {
   width: 50%;
   border: 1px solid rgb(158, 158, 158);
   padding: 10px 10px;
 }

 .reconciliationDetails_clolor {
   color: red;
 }

 .reconciliationDetails_btn {
   margin-left: 700px;
   margin-top: 10px;
 }

 .BulletinBoard {
   width: 1600px;
   height: 100px;
   border: 1px solid rgb(158, 158, 158);
   border-radius: 15px;
 }

 .Homepage_btn {
   margin-top: 15px;
   text-align: right;
 }

 .display {
   margin-left: 1300px;
 }

 .CustomerReconciliation_supplier {
   width: 50%;
   border: 1px solid rgb(158, 158, 158);
   padding: 10px 10px;
   margin: auto;
 }

 .complete {
   text-align: center;
   margin-top: 20px;
 }

 .DisplayManagement_display {
   text-align: right;
   margin-bottom: 20px;
 }

 .basicPersonalInformation_zong {
   display: flex;

 }

 .guojing {
   margin-left: 302px;
 }

 .basic {
   border: 1px solid rgb(158, 158, 158);
   width: 70%;
   border-radius: 10px;
   padding: 20px 20px;
 }

 .basicPersonalInformation_img {
   width: 20%;
   width: 302px;
   text-align: right;
   padding: 0 10px;
 }

 .basicPersonalInformation_bottom {
   width: 70%;
   border-radius: 10px;
   padding: 20px 20px;
   text-align: center;
   margin-top: 30px;
 }

 .basicPersonalInformation_bottom_a {
   width: 70%;
   border-radius: 10px;
   padding: 20px 20px;
   text-align: center;
   margin-top: 30px;
 }

 .basicPersonalInformation_enterprise {
   text-align: right;
 }

 .basicPersonalInformation_enterprise_a {
   margin-left: 20px;

 }

 .Recharge_radio .ant-radio {
   top: 75px;
   left: 75px;
 }

 .personalCenter_zong {
   display: flex;
 }

 .personalCenter_img {
   width: 15%;
   text-align: right;
   padding: 20px 20px;
 }

 .personalCenter_word {
   width: 85%;
   padding: 20px 20px;
 }

 .salesReturn_new {
   text-align: right;
   margin-bottom: 20px;
 }

 .ReturnOrder {
   border: 1px solid rgb(158, 158, 158);
   width: 90%;
   border-radius: 15px;
   padding: 10px 30px;
 }

 .addSalesReturn_input {
   margin: 20px 0px;
 }

 .Receiving_btn {
   margin: 20px 0px;
   width: 70%;
 }

 .list_btn01 {
   text-align: right;
   margin-bottom: 20px;
 }

 .list_zong {
   display: flex;
   justify-content: space-around;
 }

 .list_give {
   border: 1px solid rgb(158, 158, 158);
   width: 32%;
   padding: 10px 10px;
 }

 .callIineManagement_zong {
   display: flex;
   justify-content: space-around;
   margin-top: 40px;
 }

 .callIineManagement_zuo {
   width: 40%;
 }

 .callIineManagement_you {
   width: 50%;
 }

 .callIineManagement_btn {
   text-align: right;
   margin-bottom: 20px;
 }

 .BusinessAreaManagement_today {
   display: inline-block;
   width: 98%;
 }

 .BusinessAreaManagement_table {
   width: 90%;
   margin-left: 10%;
 }

 .BusinessAreaManagement_today ul {
   padding-left: 0;
 }

 .BusinessAreaManagement_today li {
   display: inline-block;
   text-align: center;
 }

 .BusinessAreaManagement_today li:nth-child(1) {
   width: 9%;
 }

 .BusinessAreaManagement_today li:nth-child(2) {
   width: 18.2%;
 }

 .BusinessAreaManagement_today li:nth-child(3) {
   width: 18.2%;
 }

 .BusinessAreaManagement_today li:nth-child(4) {
   width: 18.2%;
 }

 .BusinessAreaManagement_today li:nth-child(5) {
   width: 18.2%;
 }

 .BusinessAreaManagement_today li:nth-child(6) {
   width: 18.2%;
 }

 .releaseInformation_btn2 {
   text-align: right;
 }

 .PurchaseDealerInformation_btn {
   text-align: right;
   margin-top: 20px;
 }

 .FactoryDetails_synopsis {
   display: flex;
 }

 .FactoryDetails_01 {
   width: 50%;
   height: 400px;
 }

 .FactoryDetails_02 {
   width: 50%;
   height: 400px;
   padding: 0px 30px;
 }

 .MySupplier_btn {
   text-align: right;
   margin-bottom: 20px;
 }

 .revise {
   text-align: right;
 }

 .displayApplicationIist_img {
   text-align: right;
 }

 .reconciliationDetails_table {
   width: 100%;
   border: 1px solid rgb(158, 158, 158);
   padding: 20px 20px;
 }

 .reconciliationDetails_btn {
   margin-left: 750px;
   margin-top: 30px;
 }

 .quotation_a {
   text-align: right;
   margin-bottom: 20px;
 }

 .Distributor_btn {
   text-align: right;
   margin-top: 20px;
 }

 .table_11 {
   margin-top: 10px;
 }

 .cascader-box {
   margin-bottom: 8px;
   display: block;
   width: 100%;
   position: relative;
 }

 .cascader-box .anticon-minus-circle {
   position: absolute;
   right: -22px;
   top: 7px;

 }

 .newQuotation-input .ant-form-item-label {
   width: 84px;
 }

 .newQuotation-input .ant-form-item-control {
   width: 60%;
 }

 .yuHuo .ant-table-thead>tr>th[colspan]:not([colspan='1']) {
   text-align: left;
   font-weight: bold;
   font-size: 17px;
 }

 .balance .ant-table.ant-table-bordered>.ant-table-title {
   font-weight: bold;
   font-size: 17px;
   background-color: #fafafa;
 }

 .surplus .ant-table.ant-table-bordered>.ant-table-title {
   font-weight: bold;
   font-size: 17px;
   background-color: #fafafa;
 }

 .VisitDetails_title {
   border: #000000 1px solid;
   padding: 10px 10px;
 }


 .dynamic_form_nest_item-circle .cascader-box .anticon-minus-circle {
   right: 10px;
 }

 .releaseInformation .ant-form-item .ant-input-group .ant-cascader-picker {
   width: 100%;
 }

 .table-th .ant-table-thead>tr>th {
   text-align: center !important;
 }


 .reportPage .ant-table.ant-table-small .ant-table-tbody>tr>td,
 .reportPage .ant-table.ant-table-small .ant-table-thead>tr>th,
 .reportPage .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td {
   padding: 0;
 }

 .reportPage .ant-select-single .ant-select-selector .ant-select-selection-item {
   line-height: 20px;
 }

 .reportPage .ant-select:not(.ant-select-customize-input) .ant-select-selector {
   border: none;
 }

 .reportPage .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
   height: 20px;
 }

 .reportPage .ant-input {
   border: none;
   padding: 0;
   text-align: center;
 }

 .reportPage .ant-btn {
   padding: 0 6px;
   height: 20px;
   line-height: 20px;
 }

 .reportPage .ant-input-search {
   border: 1px solid #e8e8e8;
   height: 21px;
 }

 .reportPage .ant-input-search .ant-input {
   height: 18px;
 }

 .reportPage .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
   left: 0;
   top: -1px;
   background-color: transparent;
 }

 .border-aa {
   border: 1px #dfdcdc solid;
   margin-top: 5px;
 }

 .col-widsix {
   padding: 0 !important;
 }

 .col-widsix .ant-image {
   width: 100%;
   display: flex;
   align-items: center;
   text-align: center;
 }

 .col-widsix .product-con {
   padding: 2px 10px 10px;
 }

 .col-widsix .product-con p {
   margin-bottom: 2px;
 }

 .col-widsix1 {
   width: 180.83px;
   padding: 0 !important;
   margin-right: 15px;
 }

 .col-widsix1 .ant-image {
   width: 100%;
   display: flex;
   align-items: center;
   text-align: center;
 }

 .col-widsix1 .product-con {
   padding: 2px 10px 10px;
 }

 .col-widsix1 .product-con p {
   margin-bottom: 2px;
 }

 .col-widsix1:nth-child(6n) {
   margin-right: 0;
 }

 .errr .ant-table-title {
   background-color: #fafafa;
 }

 .edit-box .ant-input-affix-wrapper {
   padding: 0 10px;
 }

 .Announcement_details_content {
   margin: auto;
   width: 80%;
 }

 .Homepage_01 {
   display: flex;
   justify-content: space-between
 }

 .requ {
   border: #adacac 1px solid;
   width: 30px;
   height: 15px;
 }

 @media screen and (max-width: 1366px) {
   .ant-input-number {
     width: 100%;
   }

   .ant-input-number-input {
     padding-left: 5px;
   }

 }

 .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
 .ant-table-row-hover,
 .ant-table-row-hover>td {
   background-color: #bbddfb;
 }

 .ant-table-fixed .ant-table-row-hover,
 .ant-table-row-hover>td {
   background-color: #bbddfb;
 }

 .ant-input-number-handler-wrap {
   display: none;
 }

 .product-add-icon {
   display: none;
 }

 .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-icon {
   display: inline-flex;
 }

 .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-number {
   display: none;
 }

 .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-icon .anticon-plus-circle:first-child {
   margin-right: 2px;
 }