.line-hight34{
    line-height: 34px;
}
.site-tag-plus {
    background: #fff;
    border-style: dashed;
  }
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
.div_border{
    border: solid 1px rgb(163, 162, 162);
    border-radius: 2px;
}
.h2_font{
    font-weight: 600;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-row-hover,
.ant-table-row-hover>td {
  background-color: #bbddfb;
}

.ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover>td {
  background-color: #bbddfb;
}
.ant-input-number-handler-wrap{
  display: none;
}

.product-add-icon{
  display: none;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:hover .product-add-icon{
  display: inline-flex;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:hover .product-add-number{
  display: none;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:hover .product-add-icon .anticon-plus-circle:first-child{
margin-right: 2px;
}