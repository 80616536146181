html,
body {
  min-width: 1140px;
}


.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
  padding: 3px 8px;
  font-size: 12px;
}

.ant-tabs-tab-remove {
  margin-left: 0px;
}


.ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background-color: #fff;
}

.ant-layout {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.top-layout {
  min-width: 1200px;
}

.site-layout {
  min-width: 1200px;
}


.App {
  text-align: left;
}


.pos_rel {
  position: relative;
  position: absolute
}

.h3-tit {
  color: #fff !important;
  padding-bottom: 15px !important;
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #1890FD;
  background-color: transparent;
}

.ant-menu-dark .ant-menu-item-active {
  color: #1890FD !important;
  background-color: #e6f4ff !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: #000;
}

.ant-menu {
  margin-top: 0 !important;
  padding-top: 5px;
}

.ant-menu-submenu.ant-menu-dark .ant-menu-sub {
  width: 310px;
  background: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 10px;
}

.ant-menu-submenu.ant-menu-dark .ant-menu-item {
  color: #444;
  width: 130px;
  height: 36px;
  line-height: 36px;
  padding: 0 0 0 10px;
  margin-bottom: 0;
}

.ant-menu-submenu.ant-menu-dark .ant-menu-item:nth-child(2n) {
  margin-left: 10px;
}

.ant-menu-submenu.ant-menu-dark .ant-menu-item:hover {
  color: #1677ff;
  background-color: #e6f4ff;
  border-radius: 4px;
}

.ant-menu-submenu.ant-menu-dark>.ant-menu .ant-menu-item-selected {
  color: #1677ff;
  background-color: #e6f4ff;
  border-radius: 4px;
}

.ant-layout-header {
  padding-left: 24px;
  background: #1890ff;
  padding: 0 30px;
  height: 48px;
}

.ant-layout-content {
  overflow-y: scroll;
}

.ant-layout-sider {
  width: 160px !important;
  flex: 0 0 160px !important;
  max-width: 160px !important;
  min-width: 160px !important;
}

.ant-layout-sider-collapsed {
  width: 60px !important;
  flex: 0 0 60px !important;
  max-width: 60px !important;
  min-width: 70px !important;
}




.top-head-sider {
  max-height: 880px;
  overflow-y: scroll;
  padding-bottom: 30px;
}

.top-head-sider .collapse {
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 16px;
  color: #fff;
  background: #1890ff;
  padding: 0px 10px;
  border-radius: 2px;
  display: inline-block;
  text-align: right;
  margin: 6px 6px;
}

@media screen and (min-width: 0px) and (max-width: 1280px) {
  .top-head-sider {
    height: 500px;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  .top-head-sider {
    max-height: 580px;
  }

}

@media screen and (min-width: 1367px) and (max-width: 1440px) {
  .top-head-sider {
    max-height: 690px;
  }

  .pr-form .ant-col-xl-12,
  .pr-form1 .ant-col-xl-12 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1600px) {
  .top-head-sider {
    max-height: 650px;
  }

  .pr-form .ant-col-xl-12,
  .pr-form1 .ant-col-xl-12 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}




.ant-layout-content {
  background: #f0f2f5;
  margin-top: 0;
  min-width: 980px;
}

.ant-table-cell {
  height: 20px !important;
}

.ant-table-content .anticon {
  font-size: 16px;
}

.ant-form-item-control-input-content {
  word-break: break-all;
}


::-webkit-scrollbar {
  width: 10px;
}

.ant-layout-sider ::-webkit-scrollbar-thumb {
  background: #0b4780;
  border-radius: 3px;
}

.ant-layout-sider ::-webkit-scrollbar {
  width: 6px;
}

.ant-layout-sider ::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 0px;
}


::-webkit-scrollbar {
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}





.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px;
  border: none;
}

.clearfix {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.mar-top80 {
  margin-top: 80px;
}

.mar-lefn {
  margin-top: 20px;
}

.mar-top60 {
  margin-top: 60px;
}

.mar-top50 {
  margin-top: 50px;
}

.mar-top40 {
  margin-top: 40px;
}

.mar-top30 {
  margin-top: 30px;
}

.mar-top20 {
  margin-top: 20px;
}

.mar-top15 {
  margin-top: 15px;
}

.mar-top5 {
  margin-top: 5px;
}

.mar-top10 {
  margin-top: 10px;
}

.mar-bot30 {
  margin-bottom: 30px;
}

.mar-bot20 {
  margin-bottom: 20px;
}

.mar-bot15 {
  margin-bottom: 15px;
}

.mar-bot10 {
  margin-bottom: 10px;
}

.mar-bot5 {
  margin-bottom: 5px;
}

.mar-lef10 {
  margin-left: 10px;
}

.mar-lef13 {
  margin-left: 13px;
}


.mar-lef30 {
  margin-left: 30px;
}

.mar-lef35 {
  margin-left: 35px;
}

.mar-lef200 {
  margin-left: 200px;
}

.mar-lef20 {
  margin-left: 20px;
}

.mar-lef40 {
  margin-left: 69px;
  margin-top: 0px;
  margin-bottom: 3px;
}

.mar-lef15 {
  margin-left: 15px;
}

.mar-lef10 {
  margin-left: 10px;
}

.mar-lef5 {
  margin-left: 5px;
}

.mar-lef6 {
  margin-left: 6px;
}

.mar-rgh30 {
  margin-right: 30px;
}

.mar-rgh15 {
  margin-right: 15px;
}

.mar-rgh10 {
  margin-right: 10px;
}

.pad-15 {
  padding: 15px 30px;
}

.form_bot0 .ant-form-item {
  margin-bottom: 0;
}

.form_bot10 .ant-form-item {
  margin-bottom: 10px;
}

.clearfix {
  clear: both;
}

.color-red {
  color: #ff4d4f;
}

.color-red:hover {
  color: #ff4d4f;
}

.color-blue {
  color: #1890ff;
}

.color-span {
  color: #555;
}

.color-mgrey {
  color: #666;
}

.color-lgrey {
  color: #999;
}

.flex_l {
  display: flex;
  justify-content: flex-start;
}

.flex_c {
  display: flex;
  justify-content: center;
}

.flex_r {
  display: flex;
  justify-content: flex-end;
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

.flex_align {
  align-items: center;
}

.posi_rel {
  position: relative;
}

.posi_r {
  position: absolute;
  right: 0;
  top: 0;
}

.posi_r-50 {
  position: absolute;
  font-size: 14px;
  top: 5px;
  padding-left: 5px;
}

.posi_r6 {
  position: absolute;
  font-size: 14px;
  top: 0px;
  right: -4px;
  background: #fff;
  height: 32px;
  line-height: 30px;
  padding: 1px 8px;

  border-left: 1px solid #e8e8e8;
}

.posi_r_money {
  position: absolute;
  font-size: 16px;
  top: 4px;
  right: -20px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.order-w {
  width: 50px;
  cursor: pointer;
}

.text-blod {
  font-weight: 600;
}

.btn-left button+button {
  margin-left: 10px;
}

.wid120 {
  width: 120px;
}

.wid140 {
  width: 140px !important;
}

.wid160 {
  width: 160px !important;
}

.wid200 {
  width: 200px !important;
}

.wid300 {
  width: 300px !important;
}

.wid320 {
  width: 320px;
}

.wid400 {
  width: 400px;
}

.wid480 {
  width: 480px;
}

.ant-tabs-top>.ant-tabs-nav {
  margin: 0 0 0px 0;
}

.con_box {
  background: #fff;
  margin: 10px 0px 20px 0;
}

.con_box1 {
  background: #fff;
  margin: 10px 0px;
}

.contanir_con {
  padding: 10px 10px 20px;
}

.contanir_con1 {
  padding: 10px;
}

.authentication {
  margin-left: 150px;
}

.span_bor {
  color: #d8d8d8;
  margin: auto 6px;
}

.span_mar {
  margin: 0 6px;
}

.bor-line {
  border-top: 1px solid #e8e8e8;
}

.col-inpt-wid {
  width: 23.5%;
  margin-left: 2%;
}

.col-inpt-wid:nth-child(4n+1) {
  margin-left: 0;
}

.div-wid50 {
  width: 48%;
}

.div-wid50:nth-child(even) {
  margin-left: 4%;
}

.div-wid48 {
  width: 48%;
}

.div-wid48:nth-child(even) {
  margin-left: 4%;
}

.div-wid23 {
  width: 23.5%;
  margin-left: 2%;
}

.div-wid23:nth-child(4n+1) {
  margin-left: 0;
}

.div-wid30 {
  width: 30%;
}

.div-wid31 {
  width: 31%;
}

.div-wid36 {
  width: 36%;
}

.div-wid38 {
  width: 38%;
}

.div-wid42 {
  width: 42%;
}

.div-wid44 {
  width: 44%;
}

.div-wid46 {
  width: 46%;
}

.div-maxwid50 {
  width: 50%;
}

.div-wid52 {
  width: 52%;
}

.div-wid60 {
  width: 60%;
}

.div-wid65 {
  width: 65%;
}

.div-wid70 {
  width: 70%;
}

.col-mininp {
  width: 20%;
}

.div-wid25 {
  width: 23.5%;
  margin-left: 2%;
}

.div-wid25:nth-child(4n+1) {
  margin-left: 0;
}

.div-widleft2 {
  margin-left: 2%;
}

.mar-max-minlef2 {
  margin-left: 2%;
}

.label-left2 {
  margin-left: 2%;
}

.col-widsix {
  width: 15%;
  margin-left: 2%;

}

.col-widsix:nth-child(6n+1) {
  margin-left: 0;
}

.label-maxleft12 {
  margin-left: 12px !important;
}

.label-maxleft14 {
  margin-left: 14px !important;
}

.label-maxleft20 {
  margin-left: 20px !important;
}

.label-maxleft24 {
  margin-left: 24px !important;
}

.label-maxleft30 {
  margin-left: 30px !important;
}

.label-maxleft36 {
  margin-left: 36px !important;
}

.label-maxleft32 {
  margin-left: 32px !important;
}

.label-maxleft14 {
  margin-left: 14px !important;
}

.label-maxleft28 {
  margin-left: 28px !important;
}

.label-maxleft38 {
  margin-left: 38px !important;
}

.label-maxleft42 {
  margin-left: 42px !important;
}

.label-maxleft48 {
  margin-left: 48px !important;
}

.label-maxleft56 {
  margin-left: 56px !important;
}

.label-maxleft70 {
  margin-left: 70px !important;
}

.list-img1 {
  width: 80px;
  height: 80px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
}

.list-img1 .ant-image {
  width: 80px;
  height: 80px;
}

.list-img1 .ant-image-img {
  width: auto;
  height: 100%;
}

.list-img2 {
  width: 90px;
  height: 90px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
}

.list-img2 .ant-image {
  width: 90px;
  height: 90px;
}

.list-img2 .ant-image-img {
  height: auto;
  width: 100%;
}

.web-nav {
  height: 48px;
  line-height: 48px;
  color: #fff;
}

.web-logo {
  font-size: 20px;
  font-weight: bold;
  width: 120px;
}


.web-login {
  font-size: 14px;
}

.web-login>a {
  border: 1px solid #1890ff;
  padding: 3px 12px;
  border-radius: 2px;
}

.header-r {
  cursor: pointer;
}

.web-ico-user1 {
  background: #fff;
  padding: 4px;
  border-radius: 50%;
  color: #1890ff;
  font-weight: bold;
  margin-right: 6px;
  margin-top: 12px;
}

.web-ico-user {
  background: #fff;
  padding: 4px;
  border-radius: 50%;
  color: #1890ff;
  font-weight: bold;
  margin-right: 6px;
}

.web-login .ant-scroll-number {
  margin-top: 12px;
}

.web-ico-user2 {
  color: #1890ff;
  font-weight: bold;
  margin-right: 6px;
  font-size: 18px;
}

.ico-user {
  background: #1890ff;
  padding: 4px;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  margin-right: 8px;
}

.img-con-w15 {
  width: 15%;
}

.title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  border-bottom: 1px #e8e8e8 solid;
  padding-bottom: 8px;
}


.line {
  border-bottom: 1px #e8e8e8 solid;
  padding-bottom: 10px;
}

.line1 {
  border-bottom: 1px #e8e8e8 solid;
}

.title {
  font-size: 16px;
  color: #333;
  padding-left: 10px;
  border-left: 3px solid #1890ff;
  height: 18px;
  line-height: 18px;
  font-weight: 600;
}

.title1 {
  font-size: 16px;
  color: #333;
  height: 18px;
  line-height: 18px;
  font-weight: 600;
}

.juris-text .ant-form-item-label>label,
.juris-text {
  font-size: 14px;
  color: #333;
}


.title-data {
  font-size: 14px;
  color: #444;
}

.title-text {
  font-size: 16px;
}

.title-bgcolor {
  background: #f5f9ff;
  padding: 10px 20px;
}

.title-bgcolor1 {
  background: #f5f9ff;
  padding: 15px;
}

.title-icon {
  color: #777;
  font-size: 14px;
}

.title-bold {
  font-weight: 600;
}


.ant-upload .ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #e8e8e8;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color .3s;
  padding-top: 20px;
}

.set_user {
  padding: 15px 24px;
  height: 60px;
}

.bg_f8 {
  background: #f8f8f8;
}

.bg_ff {
  background: #fff;
}

.radio-wid {
  display: flex;
  justify-content: space-between;
  width: 66%;
}

.btn_box {
  font-size: 16px;
  text-align: center;
  margin: 35px auto 15px !important;
}

.Default_btn {
  border: 1px solid #ff4d4f;
  border-radius: 4px;
  color: #ff4d4f;
  margin-left: 30px;
}

.form-iput-bot .ant-form-item {
  margin-bottom: 0;
}

.set-price .bg_set:nth-child(even) {
  background: #fff;
}

.set-price .bg_set:nth-child(odd) {
  background: #f8f8f8;
}

li {
  list-style: none
}

.ipt-two-w {
  width: 46% !important;
}

.span_bor {
  color: #d8d8d8;
  margin: auto 4px;
}

.table-text .ant-typography {
  display: block;
  text-align: left;
}



.title_clock {
  align-items: center;
  height: 32px;
}

.title_clock h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0;
}

.con_clock {
  font-size: 16px;
  font-weight: 600;
}


.checkCertificate .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.checkCertificate .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
  width: 0 !important;
}

.checkCertificate .ant-tabs>.ant-tabs-nav,
.checkCertificate .ant-tabs>div>.ant-tabs-nav {
  position: inherit;
  height: 20px;
}

.checkCertificate .ant-tabs-tab {
  margin-right: 18px;
  margin-top: 12px;
}

.checkCertificate .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0;
  border-left: 1px solid #e8e8e8;
  padding-left: 18px;
}

.checkCertificate .sear-ipt-posi {
  margin-left: 140px;
  margin-top: -32px;
}

.checkCertificate .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.checkCertificate .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
  overflow: inherit;
}

.checkCertificate .ant-tabs-top>.ant-tabs-nav::before,
.checkCertificate .ant-tabs-bottom>.ant-tabs-nav::before,
.checkCertificate .ant-tabs-top>div>.ant-tabs-nav::before,
.checkCertificate .ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: transparent;
}

.con-tab .ant-tabs>.ant-tabs-nav,
.con-tab .ant-tabs>div>.ant-tabs-nav {
  background: #f5f9ff;
  padding-left: 30px;
}

.list-zjbg {
  border: 1px solid #e8e8e8;
  padding: 20px 30px;
  position: relative;
  margin-top: -1px;
}

.zjbg-img {
  width: 40%;
  margin-right: 30px;
  display: inline-block;
}

.list-zjbg-modal {
  border: 1px solid #e8e8e8;
  padding: 20px;
  position: relative;
  margin-top: -1px;
}

.zjbg-img-modal {
  width: 30%;
  margin-right: 30px;
  display: inline-block;
}

.zjbg-text {
  display: inline-block;
}

.data-posi {
  position: absolute;
  top: 20px;
  right: 30px;
}

.card-wid {
  width: 48%;
  display: inline-block;
}

.card-wid:nth-child(even) {
  margin-left: 4%;
}

.icon-size {
  font-size: 24px;
  color: #1890ff;
}

.login-introduction p {
  word-break: break-all;
}

.product-icon {
  font-size: 28px;
  color: #1890ff;
  margin-top: 6px;
  margin-right: 20px;
}

.rawMaterialWarehousingList-modal .ant-badge-count {
  color: #333;
  background: transparent;
  box-shadow: 0 0 0;
}

.rawMaterialWarehousingList-modal .ant-btn {
  font-size: 12px;
  border: none;
  box-shadow: 0 0 0;
  color: #333;
  border-color: transparent;
  background: transparent;
}

.rawMaterialWarehousingList-modal .ant-btn:hover,
.rawMaterialWarehousingList-modal .ant-btn:focus {
  color: #40a9ff;
  border-color: transparent;
  background: transparent;
}

.rawMaterialWarehousingList-modal .ant-btn:hover,
.rawMaterialWarehousingList-modal .ant-btn:focus,
.rawMaterialWarehousingList-modal .ant-btn:active {
  text-decoration: none;
  background: transparent;
}




.receiving_table_bot .ant-form-item {
  margin-bottom: 10px;
  margin-top: 10px;
}

.receiving_table_bot .ant-input-group.ant-input-group-compact>* {
  width: 23.9%;
}

.receiving_table_bot .ant-form-item .ant-input-group .ant-select {
  width: 100%;
}

.receiving_table_bot1 .ant-input-group.ant-input-group-compact>* {
  width: 23.9% !important;
}

.receiving_table_bot1 .ant-space-item:nth-child(1) {
  width: 98%;
}

.receiving_table_bot2 .ant-form-item {
  margin-bottom: 10px;
}

.receiving_table .ant-form-item {
  margin-bottom: 0;
}

.receiving_table .ant-typography {
  color: #1890ff;
}

.receiving_table .ant-descriptions-header {
  margin-bottom: 10px;
}

.receiving_table .ant-descriptions+.ant-descriptions {
  margin-top: 20px;
}

.receiving_table .ant-descriptions-bordered .ant-descriptions-item-label,
.receiving_table .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 4px 24px;
}

.descriptions_wid .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 120px;
}

.descriptions_wid240 .ant-descriptions-bordered .ant-descriptions-item-label {
  width: 240px;
}

.address_box .ant-form-item {
  margin-bottom: -5px;
}

.address_box .address_con {
  border: 1px #e8e8e8 dashed;
  border-radius: 4px;
  width: 100%;
  margin: 10px 0;
  padding: 5px 15px;
  transition: .3s all;
  cursor: pointer;
}

.address_box .address_con:hover {
  border: 1px dashed #1890ff;

}

.icon-account {
  width: 36px;
  height: 36px;
  color: #1890ff;
  border: 2px solid #1890ff;
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  align-items: center;
}

.modal-text {
  font-size: 18px;
  margin-left: 15px;
}

.modal-text span {
  color: #1890ff;
}

.order-card .ant-card-type-inner .ant-card-body {
  height: 280px;
}

.order-card-cor01 .ant-card-type-inner .ant-card-head {
  background: #2db7f5;
}

.order-card-cor02 .ant-card-type-inner .ant-card-head {
  background: #87d068;
}

.order-card-cor03 .ant-card-type-inner .ant-card-head {
  background: #108ee9;
}

.order-card-cor01 .ant-card-type-inner .ant-card-head-title,
.order-card-cor02 .ant-card-type-inner .ant-card-head-title,
.order-card-cor03 .ant-card-type-inner .ant-card-head-title {
  color: #fff;
}

.order-card .ant-card-type-inner .ant-card-head-title {
  font-size: 16px;
  font-weight: 600;
}

.order-card .ant-radio-wrapper {
  margin-right: 0;
}

.order-card span.ant-radio+* {
  padding-right: 0;
}

.order-card .anticon {
  font-size: 20px;
  color: #fff;
}

.order-card .money-cor01 {
  font-size: 16px;
  color: #1890ff;
  font-weight: 600;
}

.order-card .money-cor02 {
  font-size: 16px;
  color: #87d068;
  font-weight: 600;
}

.order-card .money-cor03 {
  font-size: 16px;
  color: #f50;
  font-weight: 600;
}

.modal-icon {
  font-size: 30px;
  color: #1890ff;
}


.table-tag {
  position: absolute;
  top: -10px;
  width: 50px;
  font-size: 12px;
}

.car_con {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 20px 0;
  align-items: center;
}

.car_con_span {
  font-size: 20px;

}

.th-Popconfirm-icon {
  position: relative;
  cursor: pointer;
}

.th-Popconfirm {
  display: none;
  position: absolute;
  top: -100px;
  right: -60px;
  width: 360px;
  text-align: left;
  transition: all ease-in-out 0.8s;
}

.th-Popconfirm-icon:hover .th-Popconfirm {
  display: block;
  transition: all ease-in-out 0.8s;
}

.ant-picker-calendar .ant-picker-calendar-header .ant-select {
  width: 7%;
}

.ant-picker-calendar .ant-picker-calendar-header .ant-radio-group .ant-radio-button-wrapper {
  height: 24px;
  line-height: 24px;
}

.modalExcitation .ant-btn-block {
  text-align: left;
  height: 40px;
  transition: all ease-in-out 0.2s;
  color: #1890ff;
}

.modalExcitation .ant-btn-block:hover {
  background: #1890ff;
  color: #fff;
}

.modalExcitation .posi_icon_r {
  position: absolute;
  right: 15px;
}

.modalExcitation .ant-btn-block:hover .modalExcitation>.posi_icon_r .modalExcitation .ant-btn-block:hover .modalExcitation>.color-blue {
  color: #fff !important;
}

.modal_tab .ant-tabs-nav {
  background: #f5f9ff;
  padding-left: 20px;
}

.modal_tab .ant-btn {
  padding: 0 10px;
}

.modal_tab .ant-btn>.anticon+span {
  margin-left: 20px;
}

.modal_tab .ant-descriptions-item-content {
  height: 60px;
  overflow: hidden;
  display: block;
  padding: 16px;
}

.modal_tab .ant-descriptions-item-label {
  width: 160px;
}

.modalVisitReward .posi_r-24 {
  position: absolute;
  top: 5px;
  right: -24px;
}

.div1_set .w-e-toolbar {
  z-index: 101 !important;
}

.div1_set .w-e-text-container {
  z-index: 100 !important;
}

.cityBox {
  position: relative;
}

.cityBox-after {
  position: absolute;
  right: -28px;
  top: 4px;
}

.table_p p {
  margin-bottom: 2px;
}


.kuang {
  border: #000000 1px solid;
  width: 50%;
}

.dynamic-delete-button {
  position: relative;
  margin: 0 14px;
}

.dynamic-deletea-button {
  position: relative;
  margin: 0 0 0 53px;
}

.ant-menu-submenu-title {
  padding-left: 10px !important;
}

.ant-menu-item {
  padding-left: 10px !important;
}

.ant-menu,
.ant-menu-sub,
.ant-menu-inline {
  padding-left: 10px !important;
}

.ant-menu .ant-menu-sub .ant-menu-inline {
  padding-left: 10px !important;
}

.dynamic-wenben-button {
  position: relative;
  top: 0px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}

.dynamic-shurukuang-button {
  position: relative;
  top: 0px;
  margin: 0px 0px 0px 0px;
}

.dynamic-jianshao-button {
  position: relative;
  margin: 0px 0px 0px 0px;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.modal-PROD .ant-cascader-input.ant-input,
.modal-PROD .ant-form-item .ant-select,
.modal-PROD .ant-form-item .ant-cascader-picker {
  width: 120px;
}

.table-bot-text {
  color: #666;
}

.warehousing-con .ant-picker-range {
  width: 100%;
}

.saleslssList-con .ant-form-item-control {
  width: 80%;
}

.detial-stock .ant-col-6 {
  height: 25px;
}


.ant-form-item-control {
  width: 50%;
}

.ant-picker-range {
  width: 100%;
}


.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 
}

.logtitle_box {
  border-bottom: 1px #e8e8e8 solid;
  padding-bottom: 0px;
  margin-bottom: 15px;
}

.system-default {
  margin: 0;

}


.system-default .system-title {
  padding: 20px 20px 10px;
  margin: 10px 0;
  background: #fff;
}

.system-default .con-left {
  width: 64.5%;

}

.system-default .con-right {
  width: 35%;
  margin-left: 0.5%;
}

.system-default .con-right .con-right01 {
  background: #fff;
}



.system-default .con-right .con-right01 {
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 10px;
}

.system-default .con-right .con-right01 .icon-img {
  background-image: linear-gradient(to right, #fdae64, #fd7b3c);
  border-radius: 50%;
  font-size: 26px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
}

.system-default .con-right .con-right02 .icon-img {
  background-image: linear-gradient(to right, #56d7b8, #1fa9c5);

}

.system-default .con-right .con-right03 .icon-img {
  background-image: linear-gradient(to right, #7a76fc, #5e5ffd);

}

.system-default .con-right .con-right01 p {
  color: #333;
  text-align: right;
  margin-bottom: 0;
}

.system-default .con-right .con-right01 h2.ant-typography,
.system-default .con-right .con-right01 .ant-typography h2 {
  color: #333;
  margin-bottom: 0;
}



.system-default .con-left .list01 {
  background: #fff;
  padding: 10px 10px 2px;
  text-align: right;
  margin-left: 1%;
  margin-bottom: 10px;
  width: 24.2%;
  height: 166px;
  position: relative;
  border-radius: 4px;
}

.system-default .con-left .list01:nth-child(1) {
  margin-left: 0;
}

.system-default .con-left .list01 h4.ant-typography,
.system-default .con-left .list01 .ant-typography h4 {
  margin-bottom: 10px;

}

.system-default .con-left .list02 {
  background: #fff;
  padding: 20px 20px 0;
  text-align: right;
  margin-left: 1%;
  width: 24.2%;
  height: 175px;
  position: relative;
  border-radius: 4px;
}

.system-default .con-left .list02:nth-child(1) {
  margin-left: 0;
}

.system-default .con-left .list02 h4.ant-typography,
.system-default .con-left .list02 .ant-typography h4 {
  margin-bottom: 10px;

}

.system-default .con-left .ant-image {
  width: 90px !important;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.list-btn {
  background: #fff;
  padding: 20px 20px;
  height: 90px;
  border-radius: 4px;
  text-align: center;
}

.list-btn .ant-btn {
  height: 40px;
  width: 20%;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}


.mar-bot0 .ant-form-item {
  margin-bottom: 0 !important;
  line-height: 8px;
}

.overflow {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-line;
  word-break: break-all;
}

.text {
  width: 165px;
  text-overflow: ellipsis;
  overflow: "hidden";
  white-space: "nowrap";
}

.pr-form .ant-form-item .ant-form-item-label {
  width: 80px;
}

.pr-form .estime .ant-form-item .ant-form-item-label {
  width: 100px;
}

.pr-form1 .ant-form-item .ant-form-item-label {
  width: 90px;
}

.pr-form2 .ant-form-item .ant-form-item-label {
  width: 70px;
}

.pr-form2 .estime .ant-form-item .ant-form-item-label {
  width: 94px;
}

.personal-information .ant-avatar {
  margin-left: 30px;
  margin-bottom: 20px;
}

.recommend {
  background-color: #f5f5f5;
  width: 200px;
  height: 100px;
  border-radius: 6px;
  padding: 20px;
}

.recommend .anticon {
  background-color: #f57d05;
  width: 40px;
  height: 40px;
  border-radius: 20px;

}

.recommend .anticon svg {
  margin-top: 10px;
}

.recommend p {
  color: #333;
  font-size: 15px;
  padding-top: 5px;
}

.ant-table.ant-table-small .ant-table-cell,
.ant-table.ant-table-small .ant-table-thead>tr>th {
  padding: 2px 2px !important;
}



.target-modal .ant-input-number-group-wrapper {
  display: block;
}


.deliver-goods .ant-form-item {
  margin-bottom: 3px;
  color: #666;
}

.deliver-goods .ant-form-item-label {
  color: #666;
}

.product-input {
  margin-top: 5px;
}

.product-input .ant-form-item {
  margin-bottom: 5px;
}

.pay-modal .ant-modal-body {
  height: 160px;
}

.tabs-border .ant-tabs-top>.ant-tabs-nav {
  border-bottom: 1px solid #e8e8e8;
}

.table-h .ant-switch {
  vertical-align: unset;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  padding-left: 5px;
}

.list-bottom .ant-form-item {
  margin-bottom: 0;
}

.list-bottom1 .ant-form-item {
  margin-bottom: 5px;
}

.list-bottom .ant-descriptions-row>th,
.list-bottom .ant-descriptions-row>td {
  padding-bottom: 5px;
}

.customer-type .ant-select-selection-overflow-item {
  margin-bottom: 5px;

}

.quoted-goods {
  padding: 8px;
  margin-bottom: 6px;
}

.quoted-goods p {
  margin-bottom: 0;
}

.report-form {
  width: 100%;
  background-color: #fff;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.reportf-title {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.reportf-money {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.reportf-money span {
  color: #ff7343;
  font-size: 18px;
}

.quoted-goods1 {
  height: 162px;

}

.quoted-goods1 p {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: pre-line;
  word-break: break-all;
}

.quoted-goods1 h5.ant-typography {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-line;
  word-break: break-all;

}

.reportf-list {
  margin-top: 10px;
  padding: 10px;
  background-color: #f6f6f6;
  text-align: center;
  color: #555;
  font-size: 14px;
  margin-left: 1%;
  width: 13.42%;
  border-radius: 4px;
}

.reportf-list span {
  color: #ff7343;
  font-weight: bold;
}

.reportf-list:first-child,
.reportf-list:nth-child(8) {
  margin-left: 0;
}

.printsa-table th {
  font-size: 20px;
}

@media print {
  body {
    height: auto;
  }

  @page {
    margin-top: 6mm;
    margin-bottom: 9mm;
    margin-right: 0;
    margin-left: 0;
  }

  .print-text {
    color: #000;
    font-size: 20px;
    padding: 10px 20px;
    position: relative;
  }

  .title_box1 {
    height: 70px;
    padding: 0 10px 20px;
    display: flex;
  }

  .title1 {
    font-size: 36px;
    padding-left: 10px;
    color: #000;
    line-height: 60px;
    flex: 8;
    text-align: center;
  }

  .text-l {
    line-height: 60px;
    text-align: left;
    flex: 2;
  }

  .text-r {
    text-align: right;
    line-height: 60px;
    flex: 2;
  }

  .mar-bot3 {
    margin-bottom: 3px;
  }

  .print-text .ant-form-item {
    margin-top: 2px;
    font-size: 20px;
  }

  .print-text .ant-form-item-label>label {
    color: #000;
    font-size: 20px;
  }

  .print-text .mar-top20 {
    margin-top: 20px;
  }

  .print-text .mar-bot10 {
    margin-bottom: 10px;
  }

  .print-text .page-break {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  .print-text .ant-table-thead>tr>th,
  .print-text .ant-table-small .ant-table-thead>tr>th,
  .print-text .ant-descriptions-bordered .ant-descriptions-item-label {
    background: none;
  }

  .print-text .ant-form-item-label>label,
  .print-text .ant-form-item,
  .print-text .ant-table .print-text .ant-form-item-label>label,
  .print-text .ant-form-item-control-input-content,
  .print-text .ant-typography {
    color: #000;
  }

  .print-text .ant-descriptions-item-content,
  .print-text .ant-descriptions-item-label {
    font-size: 20px;
    color: #000;
  }

  .print-text th,
  .print-text td {
    font-size: 20px;
    color: #000;
  }

  .print-text .ant-table-thead>tr>th,
  .print-text .ant-table-tbody>tr>td,
  .print-text .ant-table tfoot>tr>th,
  .print-text .ant-table tfoot>tr>td {
    color: #000;
  }

  .print-text .ant-table.ant-table-bordered>.ant-table-container {
    border-top: 1.5px solid #000;
    border-left: 1.5px solid #000;
    border-collapse: collapse;
  }

  .print-text .title_box1,
  .print-text .ant-table-thead>tr>th,
  .print-text .ant-table-tbody>tr>td,
  .print-text .ant-table-summary>tr>th,
  .print-text .ant-table-summary>tr>td {
    border-bottom: 1.5px solid #000;
    border-collapse: collapse;
  }

  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
    border-top: 1.5px solid #000;
    border-bottom: 1.5px solid #000;
    border-collapse: collapse;
  }

  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
  .print-text .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
    border-right: 1.5px solid #000;
    border-collapse: collapse;
  }

  .print-text .ant-table-summary {
    background: transparent;
  }

  .print-text .ant-descriptions-bordered .ant-descriptions-view {
    border: 1.5px solid #000;
    border-right: none;
    border-bottom: none;
    border-collapse: collapse;
  }

  .print-text .ant-descriptions-bordered .ant-descriptions-row {
    border-bottom: 1.5px solid #000;
    border-collapse: collapse;
  }

  .print-text .ant-descriptions-bordered .ant-descriptions-item-label,
  .print-text .ant-descriptions-bordered .ant-descriptions-item-content {
    border-right: 1.5px solid #000;
    border-collapse: collapse;
  }

  .print-text table {
    border: 1.5px solid #000;
    border-bottom: none;
    border-collapse: collapse;
  }

  .print-text .ant-row .ant-form-item {
    margin-bottom: 2px;
  }

  .ant-message-notice-content {
    display: none;
  }

  .print-text .stable tr>td:nth-child(2) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .print-text .fontsize {
    font-size: 16px;
  }

  .print-text .ant-table-tbody>tr>td.smallsize {
    font-size: 16px;
  }
}


.ztjc {
  font-weight: 500;
  color: #000;
}

.butor-img .ant-image-img {
  max-height: 75px;
}

.table-red,
.ant-table-tbody>.table-red:hover>td {
  background: #fbb7b7;
}

.table-green,
.ant-table-tbody>.table-red:hover>td {
  background: #28d069;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-row-hover,
.ant-table-row-hover>td {
  background-color: #bbddfb;
}

.ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover>td {
  background-color: #bbddfb;
}

.table-f {
  background: #fff;
}

.table-bg-red {
  background: #e47575;
}

.table-bg-blue {
  background: #6b81ef;
}




.purchase-date .ant-input-group-addon {
  padding: 0;
  border: none;
}

.purchase-date .ant-picker-suffix {
  margin: 0 4px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.product-add-icon {
  display: none;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-icon {
  display: inline-flex;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-number {
  display: none;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-icon .anticon-plus-circle:first-child {
  margin-right: 2px;
}

.reportpage-form .ant-form-item {
  margin-bottom: 0;
}

.ant-menu-dark .ant-menu-item:hover .ant-menu-title-content {
  overflow: visible;
}

.novice-wrapper {
  background: #fff;
  height: 100%;
  padding: 20px;
}

.novice-box {
  height: 405px;
  width: 1260px;
  position: relative;
  margin: 180px auto 100px;
  background: url(../img/directionline.png) no-repeat;
}

.novice-step {
  position: absolute;
  cursor: pointer;
}

.step-one {
  left: 35px;
  top: 144px;
}

.description {
  vertical-align: middle;
  display: inline-block;
  margin-top: -25px;
  margin-left: 10px;
  width: 170px;
}

.description-title {
  color: #222;
  font-size: 16px;
  font-weight: 600;
}

.description p {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.step-two {
  left: 200px;
  top: -84px;
}

.step-three {
  left: 402px;
  top: 84px;
}

.step-four {
  left: 350px;
  top: 290px;
}

.step-five {
  left: 708px;
  top: 210px;
}

.step-six {
  left: 840px;
  top: -84px;
}

.step-seven {
  left: 1014px;
  top: 120px;
}


@media screen and (max-width: 1440px) {
  .col-inpt-wid {
    width: 48%;
    margin-left: 4%;
  }

  .col-inpt-wid:nth-child(2n+1) {
    margin-left: 0;
  }

  .label-minleft14 {
    margin-left: 14px !important;
  }

  .label-minleft28 {
    margin-left: 28px !important;
  }

  .label-minleft42 {
    margin-left: 42px !important;
  }

  .label-minleft56 {
    margin-left: 56px !important;
  }

  .label-minleft70 {
    margin-left: 70px !important;
  }

  .div-wid50 {
    width: 100%;
  }

  .div-wid50:nth-child(even) {
    margin-left: 0%;
  }

  .mar-mintop15 {
    margin-top: 15px;
  }

  .div-minwid36 {
    width: 36%;
  }

  .div-minwid100 {
    width: 100% !important;
  }

  .mar-mintop15 {
    margin-top: 15px;
  }

  .mar-minbot15 {
    margin-bottom: 15px;
  }

  .col-mininp {
    width: 33% !important;
  }

  .div-wid25 {
    width: 32%;

  }

  .div-wid25+.div-wid25 {
    margin-left: 2%;
  }

  .div-wid25:nth-child(3n+1) {
    margin-left: 0 !important;
  }

  .col-widsix {
    width: 23.5%;
  }

  .col-widsix:nth-child(4n+1) {
    margin-left: 0;
  }


  .div-widleft2 {
    margin-left: 0%;
  }

  .mar-max-minlef2 {
    margin-left: 0%;
  }

  .label-left2 {
    margin-left: 4%;
  }

  .novice-wrapper {
    padding: 0;
  }

  .novice-box {
    height: 405px;
    width: 1100px;
    position: relative;
    margin: 160px auto 60px;
    background: url(../img/directionline.png) no-repeat;
    background-size: 82%;
  }

  .novice-step .ant-image-img {
    width: 88%;
  }

  .description {
    margin-left: 2px;
    margin-top: -10px;
  }

  .step-one {
    left: 35px;
    top: 130px;
  }

  .step-two {
    left: 160px;
    top: -80px;
  }

  .step-three {
    left: 336px;
    top: 74px;
  }

  .step-four {
    left: 300px;
    top: 250px;
  }

  .step-five {
    left: 610px;
    top: 180px;
  }

  .step-six {
    left: 710px;
    top: -84px;
  }

  .step-seven {
    left: 870px;
    top: 100px;
  }

}

@media screen and (max-width: 1360px) {
  .novice-box {
    margin: 120px auto 30px;
    height: 380px;
  }
}

.novice-modal a {
  display: block;
  font-size: 16px;
  line-height: 30px;
}

.novice-modal .modal-p {
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-container .ant-modal-body {
  min-height: auto;
  max-height: 600px;
  overflow: auto;
}

.index_detail_style {
  display: flex;
  justify-content: space-evenly;
  margin: 0 5%;
  background-color: #faf9f9;
}

.index_detail_style:hover {
  opacity: 0.8;
  transform: calc(1.1);
}

.ant-modal.menu-modal {
  left: 160px;
  top: 240px;
  margin: 0;
}

.menu-modal .ant-modal-header {
  padding: 10px;
}

.menu-modal .ant-modal-close-x {
  width: 43px;
  height: 43px;
  line-height: 43px;
}

.menu-modal .ant-modal-body {
  padding: 10px;
}

.menu-modal .menu-modal-item {
  line-height: 40px;
  padding-left: 10px;
}

.menu-modal .menu-modal-item:hover {
  color: #1890ff;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  background: #e6f4ff;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 1px;
  height: 100%;
  bottom: 0;
  right: -1px;
  cursor: col-resize;
  background: rgb(233, 229, 229);
  z-index: 999;
}

.coreProduct-modal .ant-modal-content {
  height: 600px;
}

.coreProduct-modal .ant-modal-body {
  max-height: 530px !important;
}

.tasks-search.ant-input-search .ant-input {
  height: 30px;
}

.tasks-search.ant-input-search .ant-btn {
  height: 32px;
}

.management-daily .ant-form-item {
  margin-bottom: 5px;
}

.md-box {
  width: 100%;
  height: 340px;
  margin-top: 10px;
}

.md-box .md-content {
  width: 49.5%;
  border: 1px solid #eee;
  padding: 8px 8px 50px 8px;
}

.data-title {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.md-border {
  border: 1px solid #eee;
  padding: 8px 8px 36px 8px;
}