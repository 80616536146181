.ant-tabs-tab {
    padding-top: 0
}

.certificateView_top {
    width: 95%;
    margin: 20px auto !important;
    background: rgb(242, 242, 242);
    padding: 20px 20px 0 20px !important;
}

.certificateView_bottom {
    width: 95%;
    margin: 0 auto;
    background: rgb(242, 242, 242);

}

.Certifikata_title {
    margin: 20px;
}

.Certifikata_tab {
    width: 85%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.Certifikata_border {
    border-right: 1px solid #b2b2b2;
}

.certificateView_bottom .ant-table-thead>tr>th {
    background-color: #81d3f8;
}

.certificateView_bottom .ant-table-container tr th,
.certificateView_bottom .ant-table-container tr td,
.certificateView_bottom .ant-table table,
.certificateView_bottom .ant-table.ant-table-bordered>.ant-table-container {
    border-color: #ababab !important;
}

.CertificateView_title {
    text-align: center;
    font-weight: 700;

}

.certificates-list .certificates-list-item .certificates-info {
    display: flex;
    padding: 20px;
    border-top: 1px solid rgb(184, 184, 184);
}

.certificates-list .certificates-detailed {
    margin-left: 16px;
}

.lack_query {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.company {
    margin-right: 20px;
}

.CertificateView_aaa {
    position: relative;
}

.CertificateView_mysuo {
    position: absolute;
    right: 5px;
    top: -23px;
}

.distribution_box {
    background: rgb(242, 242, 242);
    margin-bottom: 20px;
}

.deliverGoods_box .ant-form-item {
    margin-bottom: 0;
}

.SecondLogin {
    width: 50%;
    height: 80%;
    margin: 20px auto !important;
}

.SecondLogin_switch>span {
    display: inline-block;
    width: 50%;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    color: rgb(137, 137, 137);
    font-size: large;
}

.SecondLogin_switch .SecondLogin_switch_one {
    border-right: 1px solid rgb(137, 137, 137);
}

.SecondLogin_bottom {
    width: 100%;
    margin: 30px auto !important;
    padding: 40px 30px;
    background: #fff;
    border-radius: 8px;
    box-sizing: border-box;
}

.Register {
    width: 50%;
    margin: 30px auto;

}

.SupplierList_card_title {
    margin-bottom: 15px;
}


.SupplierList_search_box {
    position: absolute;
}

.SupplierList_search {
    z-index: 1;
    position: relative;
    top: 24px;
    left: 24px;
}

.supplier_span {
    padding: 0 5px;
    line-height: 30px
}

.SupplierName_text {
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid #797979;
}

.New_supplier_span {
    padding-right: 10px;


}

.VendorDetails_top_box {
    background-color: #f2f2f2;
    margin-bottom: 20px;
}

.VendorDetails_title {
    padding: 20px 20px 0;
}

.VendorDetails_btn_bottom {
    padding: 30px;
}

.VendorDetails_inp_status {
    pointer-events: none;
    cursor: default;
}

.customerDetails .ant-form-item {
    margin-bottom: 0px;
}

.customerList_date_box {
    background-color: #f2f2f2;
    padding: 30px;
}


.news_account {
    position: relative;
}

.news_account a {
    position: absolute;
    top: 6px;
    left: 340px;
}


.CompanyCertificate {
    width: 50%;
    margin: 0 auto;
}

.company-certificates .ant-col>.ant-list-item {
    border: 1px solid #e8e8e8;
    padding: 10px;
}

.CompanyCertificate_text {
    border: 1px solid #797979;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}

.qualityList {
    padding: 20px;
    border-bottom: 1px solid #797979;
}

.warehouse_box {
    position: relative;
}

.warehouse {
    width: 100px;
    position: absolute;
    top: 5px;
    left: 267px;
}

.Add_item_Check {
    margin-top: 30px;
}

.Add_item {
    border: 1px solid #797979;
    margin-bottom: 10px;
    padding: 10px 70px;
}

.Add_item_span {
    display: inline-block;
    width: 200px;
    padding: 0 70px;
}

.Examine {
    margin-bottom: 20px;
}

.Inven_Mana_NewAdd {
    padding: 0 50px;
    position: relative;
}

.Inven_Mana_NewAdd .Inven_Mana_Plus {
    position: absolute;
    top: 163px;
    left: 860px;
}

.war_AddReceipt {
    padding: 0 50px;
    position: relative;
}

.war_AddReceipt .war_AddReceipt_Plus {
    position: absolute;
    top: 140px;
    left: 860px;
}

.detailedj .ant-form-item {
    margin-bottom: 0px;
}

.purchase_children {
    position: absolute;
    top: 4px;
    right: 0;
}

.pos_rel_img25 {
    position: absolute;
    top: 0px;
    left: 5px;
}

.upload-bot .ant-form-item {
    margin-bottom: 0px;
}

.yuefen .ant-picker {
    width: 100%;
}

.acma-box {
    margin-top: 10px;
}

.acma-box .ant-tabs-nav {
    background-color: #fff;
    padding-left: 10px;
}

.acma-box .contanir_con {
    margin-top: 0;
}

.acma-box .ant-tabs-tab {
    padding: 10px 0;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-row-hover,
.ant-table-row-hover>td {
    background-color: #bbddfb;
}

.ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover>td {
    background-color: #bbddfb;
}

.ant-input-number-handler-wrap {
    display: none;
}

.product-add-icon {
    display: none;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-icon {
    display: inline-flex;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-number {
    display: none;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td:hover .product-add-icon .anticon-plus-circle:first-child {
    margin-right: 2px;
}